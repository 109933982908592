import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { useField } from '@hotelplan/components.common.forms';
import {
  Configuration,
  getShortInformation,
  ITravelRoomsModalProps,
  ITravelRoomsState,
  TravelRoomsDropdown,
  TravelRoomsField,
  TravelRoomsMobileModal,
} from '@hotelplan/components.common.travel-rooms';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import {
  trackTravelRooms,
  TravelRoomsEventType,
  TravelApplySourceType,
} from '@hotelplan/libs.tracking';
import {
  ENTER_KEY,
  ESCAPE_KEY,
} from 'components/domain/keyboard-navigation/keyboard-navigation.constant';
import { useListenKeyboardClick } from 'components/domain/keyboard-navigation/useListenKeyboardClick';
import {
  EFdrFlightTravelType,
  U_Extended_TravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import NoSsr from 'fdr/components/local/no-ssr/no-ssr';
import { TravelRoomsDropdownWrap } from './fdr-travel-rooms-field-container.styles';
import { IFdrTravelRoomsFieldContainerProps } from './fdr-travel-rooms-field-container.types';
import { useFdrFourTravelRooms } from './use-fdr-four-travel-rooms';

export default function FdrTravelRoomsFieldContainer({
  side = 'rightSide',
  sticky,
  customLabel,
}: IFdrTravelRoomsFieldContainerProps): React.ReactElement {
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();
  const [travelType] =
    useField<U_Extended_TravelType | null>(`extended_travelType`);
  const [t] = useTranslation('search');

  const isSearchForFourRoomsEnabled =
    travelType.fdr !== EFdrFlightTravelType.Flight;

  const {
    value: travelRooms,
    setValue: setTravelRooms,
    isValid,
  } = useFdrFourTravelRooms();

  const [isFieldOpened, openField, closeField] = useToggleState(false);

  const moreButtonContainerRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  const travelRoomsInputValue = travelRooms
    ? getShortInformation(t, travelRooms, sticky)
    : '';

  const isManual =
    channelType === AuthChannelType.B2B &&
    travelType.fdr !== EFdrFlightTravelType.Flight;

  const configuration: Configuration = {
    hasApplyBtn: true,
    hasCloseBtn: false,
    infoNotification: {
      enable: false,
    },
    isTrackSource: !mobile,
    focusFirstDOBElement: true,
    isIOSDobInputDisabled: true,
    isSearchForFourRoomsEnabled,
    isRoomsInputOnBottom: !isManual && isSearchForFourRoomsEnabled,

    // rules for desktop only!
    forceApplyOnOverlay: !mobile,
    forceSaveOnEveryChange: !mobile,
  };

  useOnClickOutside<HTMLDivElement | HTMLInputElement>(
    [moreButtonContainerRef, textInputRef],
    () => {
      if (!isValid) return;
      if (travelRooms && isFieldOpened) {
        trackTravelRooms({
          type: TravelRoomsEventType.TRAVEL_ROOMS_APPLY,
          payload: {
            adults: travelRooms.adults,
            children: travelRooms.childrenDobs,
            rooms: travelRooms.rooms,
            source:
              configuration.isTrackSource && TravelApplySourceType.BACKGROUND,
          },
        });
      }
      closeField();
    },
    isFieldOpened && !mobile
  );

  const escapeClickListener = (e: KeyboardEvent) => {
    if (e.key === ESCAPE_KEY && isFieldOpened) {
      closeField();
    }
  };

  useListenKeyboardClick(isFieldOpened, escapeClickListener);

  function onTrackTR() {
    if (isFieldOpened) return; // Shouldn't track while input already opened
    trackTravelRooms({ type: TravelRoomsEventType.TRAVEL_ROOMS_OPEN });
  }

  function saveTravelRooms(nextState: ITravelRoomsState): void {
    setTravelRooms({
      ...nextState,
      travellersDistribution: isManual
        ? nextState.travellersDistribution
        : null,
    });

    if (mobile) {
      closeField();
    }
  }

  const travelRoomsProps: ITravelRoomsModalProps = {
    travelRooms,
    configuration,
    saveTravelRooms,
    show: isFieldOpened,
    close: closeField,
    isManualDistribution: isManual,
  };

  return (
    <NoSsr>
      <TravelRoomsField
        className={isFieldOpened && 'open'}
        inputRef={textInputRef}
        inputValue={travelRoomsInputValue}
        customLabel={customLabel}
        inputIcon={{ name: travelRooms?.rooms === 0 ? 'pax' : 'hotel' }}
        onClick={() => {
          onTrackTR();
          openField();
        }}
        onKeyPress={e => {
          if (e.key === ENTER_KEY) {
            e.preventDefault();
            onTrackTR();
            openField();
          }
        }}
      >
        {mobile && <TravelRoomsMobileModal {...travelRoomsProps} />}
        {!mobile && (
          <TravelRoomsDropdownWrap>
            <TravelRoomsDropdown
              side={side}
              dropdownRef={moreButtonContainerRef}
              {...travelRoomsProps}
            />
          </TravelRoomsDropdownWrap>
        )}
      </TravelRoomsField>
    </NoSsr>
  );
}
